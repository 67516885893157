import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Teknik Sorunlara Hızlı Çözümünüz
			</title>
			<meta name={"description"} content={"TechEase Solutions olarak her teknik sorunun bir çözümü olduğuna inanıyoruz."} />
			<meta property={"og:title"} content={"Teknik Sorunlara Hızlı Çözümünüz"} />
			<meta property={"og:description"} content={"TechEase Solutions olarak her teknik sorunun bir çözümü olduğuna inanıyoruz."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Header>
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/2-2.jpg?v=2024-07-08T13:23:10.893Z) 0% 0%/cover"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Bizi ziyaret et
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Mecidiyeköy, Akıncı Bayırı sokağı No:10, 34394 Şişli/İstanbul, Türkiye
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Bize yazın
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="info@crysperia.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									info@crysperia.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Bizi arayın
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+902122742574
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					align-items="stretch"
					flex-direction="column"
					justify-content="center"
					text-align="right"
				>
					<Text margin="0px 0px 0px 0px" font="--headline2">
						Teknolojinizi Tekrar Mükemmel Şekilde Çalıştıralım!
					</Text>
					<Text margin="3rem 0px 0px 0px" font="--base">
						Teknolojik sorunların sizi yavaşlatmasına izin vermeyin. TechEase Solutions ile bugün iletişime geçin ve ihtiyaçlarınızı ön planda tutan sorunsuz bir onarım hizmetini deneyimleyin. Unutmayın, TechEase Solutions olarak biz "Teknik Arızalara Hızlı Çözümünüz!"
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});